@import '../../../../scss/theme-bootstrap';

.site-footer-formatter {
  font-family: $font--neue-haas-unica-pro;
  position: relative;
  clear: both;
  margin: 0 auto;
  text-align: $ldirection;
  color: $color-medium-grey;
  @if $cr22 {
    padding-top: 40px;
    @include breakpoint($landscape-up) {
      padding-top: 80px;
    }
    .mpp-container--dark-background & {
      background: $color-neutral-black;
    }
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    @include breakpoint($landscape-up) {
      max-width: $container-max-width;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
  }
  &__top {
    flex: 0 100%;
    @include breakpoint($landscape-up) {
      flex: 0 50%;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    @include breakpoint($landscape-up) {
      flex: 0 50%;
    }
  }
  &__links {
    display: block;
    margin: 0;
    text-decoration: none;
    width: 100%;
    word-wrap: break-word;
    @include breakpoint($landscape-up) {
      max-width: 100%;
      flex: 1 1 auto;
      width: auto;
    }
  }
  &__bottom-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 52px 0 20px;
    @include breakpoint($landscape-up) {
      align-items: center;
      margin: 32px 0 40px;
    }
    &--left,
    &--right {
      display: flex;
    }
    .site-footer-formatter__accessibility {
      display: inline-block;
      text-align: center;
      @include breakpoint($landscape-up) {
        width: auto;
      }
      a {
        text-decoration: none;
      }
      span {
        font-weight: normal;
        margin-#{$rdirection}: 10px;
      }
    }
  }
  &__copyright {
    @include breakpoint($landscape-up) {
      width: auto;
    }
    @if $cr22 {
      .mpp-container--dark-background & {
        color: $color-neutral-light-gray;
      }
    }
    p {
      margin: 0;
    }
  }
  &__global {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px 0;
  }
  &__terms-links {
    margin-bottom: 30px;
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      margin-bottom: 0;
    }
  }
  &__language-chooser {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &__term-link {
    width: 100%;
    text-align: center;
    padding: 0;
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0 39px 0 0);
      display: inline-block;
      width: auto;
    }
    a {
      text-decoration: none;
      color: $color-medium-grey;
      @if $cr22 {
        .mpp-container--dark-background & {
          color: $color-neutral-light-gray;
        }
      }
    }
  }
  &__bottom {
    @include breakpoint($landscape-up) {
      padding-top: 30px;
      max-width: $container-max-width;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
  }
  // MPP dark background styles overrides.
  @if $cr22 {
    .mpp-container--dark-background & {
      .email-signup-panel {
        &__wrapper {
          color: $color-white;
        }
        &__title {
          .custom-text {
            color: $color-white;
          }
        }
        .email-signup-panel__field {
          background-color: $color-neutral-black;
          border-bottom: 1px solid $color-neutral-light-gray;
          color: $color-white;
        }
        &__button {
          input {
            @include button--secondary;
          }
        }
      }
    }
  }
}

body {
  // footer simplified on all checkout pages, including the cart page.
  &#viewcart,
  &#samples,
  &#index.active-panel-shipping,
  &#index.active-panel-payment,
  &#index.opc__shipping,
  &#index.opc__review,
  &#shipping,
  &#billing,
  &#payment,
  &#payment_select,
  &#review,
  &#confirm,
  &#index.checkout.elc-user-state-logged-in {
    .site-footer-formatter {
      &__wrapper,
      &__language-chooser {
        display: none;
      }
      &__terms-links {
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
    }
  }
  &#index {
    &.checkout {
      &.active-panel-registration {
        .site-footer-formatter {
          &__wrapper,
          &__language-chooser {
            display: none;
          }
          &__terms-links {
            display: none;
            @include breakpoint($landscape-up) {
              display: block;
            }
          }
        }
      }
    }
  }
}
